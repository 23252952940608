import md5 from 'md5';
import { defineComponent } from 'vue';
import useUsers from '@/compositions/useUsers';
import { cloneDeep } from 'lodash';
import { useToast } from 'vue-toastification';
import { Selector, Table, Alert, ModalBox } from '@/components/UI';
export default defineComponent({
    name: 'UsersTable',
    components: {
        Selector,
        Table,
        Alert,
        ModalBox
    },
    setup: () => ({
        ...useUsers(),
        toast: useToast()
    }),
    data: () => ({
        perpage: 10,
        editUser: {
            username: '',
            password: '',
            _id: ''
        },
        selectedUserId: null,
        newPassword: '',
        newPassword2: '',
        message: '',
        deleteModal: false,
        sendNotificationModal: false,
        createModal: false,
        permissions: [{ value: 'user', text: 'User (Managing teams only)' },
            { value: 'admin', text: 'Admin' }],
        currentpage: 1,
        fields: [
            { key: 'username', label: 'Username' },
            { key: 'permissions', label: 'Permissions' },
            { key: 'actions', label: 'Actions' }
        ]
    }),
    computed: {
        usertable() {
            const userlist = [];
            Object.values(this.users).forEach(element => {
                const user = {
                    username: element.username,
                    permissions: element.rights !== undefined ? element.rights : 'user',
                    id: element._id,
                    devices: element.notification === undefined ? 0 : element.notification.length
                };
                userlist.push(user);
            });
            return userlist;
        },
        inputConflict() {
            if ((this.newPassword !== '' || this.newPassword2 !== '') && this.newPassword !== this.newPassword2) {
                return 'Password fields do not match';
            }
            const otherUser = Object.values(this.users).find(user => {
                return this.editUser.username === user.username && this.editUser._id !== user._id;
            });
            if (otherUser) {
                return 'Username already exists';
            }
            return '';
        }
    },
    methods: {
        async deleteUser() {
            if (this.selectedUserId === null) {
                this.toast.error(`Could not find the user to delete`);
                return;
            }
            const user = this.users[this.selectedUserId];
            if (user === undefined) {
                this.toast.error(`Could not find the user to delete`);
                return;
            }
            const name = user.username;
            try {
                await this.$store.direct.dispatch.users.delete(this.selectedUserId);
                this.toast.success(`User ${name} deleted`);
            }
            catch (error) {
                this.toast.success(`Delete user ${name} with error, ${error}`);
            }
            this.deleteModal = false;
        },
        startEdit(id) {
            this.selectedUserId = id;
            this.createModal = true;
            const user = this.users[this.selectedUserId];
            if (user !== undefined) {
                this.editUser = cloneDeep(user);
            }
        },
        async updateUser() {
            if (this.inputConflict !== '') {
                return;
            }
            if (this.newPassword !== '') {
                this.editUser.password = md5(this.newPassword);
            }
            try {
                await this.$store.direct.dispatch.users.update(this.editUser);
                this.toast.success(`User ${this.editUser.username} updated`);
            }
            catch (error) {
                this.toast.error(`User ${this.editUser.username} was not update updated, ${error}`);
            }
            this.createModal = false;
        },
        async sendNotification() {
            if (this.selectedUserId === null) {
                this.toast.error('Could not send message, user unknown');
                return;
            }
            const data = { userId: this.selectedUserId, message: this.message };
            try {
                await this.$store.direct.dispatch.users.sendNotification(data);
                this.toast.success(`Message send`);
            }
            catch (error) {
                this.toast.error(`Could not send message, ${error}`);
            }
            this.sendNotificationModal = false;
        }
    }
});
