import UsersTable from '@/components/Users/UsersTable';
import { Card, Button, Selector, Alert, ModalBox } from '@/components/UI';
import md5 from 'md5';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'UsersTab',
    components: {
        UsersTable,
        Card,
        Button,
        Selector,
        Alert,
        ModalBox
    },
    data: () => ({
        conflict: '',
        username: '',
        password1: '',
        password2: '',
        showModal: false,
        rights: 'user',
        errormessage: '',
        permissions: [
            { value: 'user', text: 'User (Managing teams only)' },
            { value: 'admin', text: 'Admin' }
        ]
    }),
    methods: {
        addNewUser() {
            this.conflict = '';
            if (this.password1 === '') {
                this.conflict = 'Please enter a password';
                return;
            }
            if (this.password1 !== this.password2) {
                this.conflict = 'Password fields do not match';
                return;
            }
            const user = {};
            user.username = this.username;
            user.password = md5(this.password1);
            user.rights = this.rights;
            this.$store.direct.dispatch.users.add(user);
            this.showModal = false;
        },
        startNewUser() {
            this.conflict = '';
            this.errormessage = '';
            this.password1 = '';
            this.password2 = '';
            this.username = '';
            this.rights = 'user';
            this.showModal = true;
        }
    }
});
