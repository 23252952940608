import store from '@/store';
import { computed, onMounted, onUnmounted } from 'vue';
const useUsers = () => {
    onMounted(async () => store.dispatch.users.subscribe());
    onUnmounted(async () => store.dispatch.users.unsubscribe());
    return {
        users: computed(() => store.state.users)
    };
};
export default useUsers;
